import React from 'react';
import Banner from "../banner/Banner";
import {Skills} from "../skills/Skills";

const Home = () =>{
    return(
        <>
            <Banner />
            <Skills />
        </>
    )
}

export default Home;